export const Formats = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle: 'subtitle',
} as const;

type FormatsProp = (typeof Formats)[keyof typeof Formats];
type TagType = Exclude<FormatsProp, 'subtitle'>;

export type HeadlineProps = {
  tag: TagType;
  title?: string | React.ReactNode[];
  subtitle?: string;
  text?: string;
  intro?: string;
  titlePreviewId?: `#${string}`;
  subtitlePreviewId?: `#${string}`;
  introPreviewId?: `#${string}`;
  textPreviewId?: `#${string}`;
  className?: string;
  textClassName?: string;
  introTextClassName?: string;
  isFlexItem?: boolean;
  variant?: FormatsProp;
  emphasize?: 'title' | 'subtitle';
};

export type TypoProps = {
  variant?: FormatsProp;
  previewId?: string;
  tag?: TagType | 'span';
  className?: string;
  children?: React.ReactNode | string;
};
